import { Store } from "./src/redux/store";

export const onClientEntry = () => {
    // Function to decode URL-encoded strings
    function decodeUrlEncoded(str) {
        return decodeURIComponent(str.replace(/\+/g, " "));
    }

    // Function to fix URL-encoded content
    function fixUrlEncodedContent(element) {
        if (element.id === "cookieconsent:desc") {
            element.innerHTML = decodeUrlEncoded(element.innerHTML);
        }
    }

    // Set up a MutationObserver to watch for DOM changes
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.type === "childList") {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.id === "cookieconsent:desc") {
                            fixUrlEncodedContent(node);
                        }
                        node.querySelectorAll("#cookieconsent\\:desc").forEach(
                            fixUrlEncodedContent
                        );
                    }
                });
            }
        });
    });

    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true });

    window.onload = () => {
        // DNSS snippet
        const dnsLink = document.createElement("link");
        dnsLink.rel = "stylesheet";
        dnsLink.type = "text/css";
        dnsLink.href =
            "https://cdn.clarip.com/purdue/donotsell/assets/css/donotsell-extended.min.css";
        document.head.appendChild(dnsLink);

        // Global variables
        window.globalDnsDeployment = false;
        window.claripHost = "purdue.clarip.com";
        window.claripCdnHost = "cdn.clarip.com";
        window.clientName = "purdue";

        // Additional Do Not Sell variables
        window.doNotSellCookieName = "clarip_dns_cookie";
        window.doNotSellCookieValue = 1;
        window.doNotSellCookieExpirationAge = 1825;
        window.dnsControllerType = "option-2";
        window.dnsSubmissionUrl =
            "https://purdue.clarip.com/dsr/success?brand=ZURNAI";
        window.standardDsrFormUrl =
            "https://purdue.clarip.com/dsr/create?brand=ZURNAI&type=3";
        window.createDoNotSellLink = 0;
        window.doNotSellLinkSelector = "#clarip-do-not-sell-link";
        window.enableEnforcementScope = false;

        // Add the GPC processing variable
        window.enableGPCProcessing = true; // Adjust this value if needed

        const dnsScript = document.createElement("script");
        dnsScript.src =
            "https://cdn.clarip.com/purdue/donotsell/assets/js/donotsell-block.min.js";
        document.body.appendChild(dnsScript);

        // Add the extended manager script
        const dnsManagerScript = document.createElement("script");
        dnsManagerScript.src =
            "https://cdn.clarip.com/purdue/donotsell/assets/js/donotsell-manager-extended.min.js";
        document.body.appendChild(dnsManagerScript);

        // Cookie-consent snippet
        const ccLink = document.createElement("link");
        ccLink.rel = "stylesheet";
        ccLink.type = "text/css";
        ccLink.href =
            "//cdn.clarip.com/purdue/cookieconsent/assets/css/cookieconsent.min.css";
        document.head.appendChild(ccLink);

        const ccScript = document.createElement("script");
        ccScript.src =
            "//cdn.clarip.com/purdue/cookieconsent/assets/js/cookieconsent.min.js";
        document.body.appendChild(ccScript);

        // Additional cookie consent variables
        window.claripClientName = "purdue";
        window.claripCookieConsentHost = "purdue.clarip.com";
        window.claripCookieManagerIdentifier = "076255a103e1b2add056";

        const ccManagerScript = document.createElement("script");
        ccManagerScript.src =
            "//cdn.clarip.com/purdue/cookieconsent/assets/js/clarip-cookie-manager.min.js";
        document.body.appendChild(ccManagerScript);

        // Function to set the consent cookie
        function setConsentCookie() {
            console.log("Consent button clicked. Setting cookie...");

            const consentCookie = "hysinglaer_clarip_consent";
            const currentDomain = window.location.hostname;
            const cookieValue = "true";
            const expiryDays = 365;

            const expiryDate = new Date();
            expiryDate.setTime(
                expiryDate.getTime() + expiryDays * 24 * 60 * 60 * 1000
            );
            const expires = "expires=" + expiryDate.toUTCString();

            document.cookie =
                consentCookie +
                "=" +
                cookieValue +
                ";" +
                expires +
                ";domain=" +
                currentDomain +
                ";path=/";
        }

        // Attach event listener to '.cc-allow' button
        document.addEventListener("DOMContentLoaded", () => {
            const consentButton = document.querySelector(".cc-allow");
            if (consentButton) {
                console.log("Attaching event listener to '.cc-allow' button");
                consentButton.addEventListener("click", setConsentCookie);
            } else {
                console.log("'.cc-allow' button not found");
            }
        });

        // Dispatch DOMContentLoaded event
        setTimeout(() => {
            window.dispatchEvent(new Event("DOMContentLoaded"));
        }, 1000);
    };
};

export const wrapRootElement = Store;
